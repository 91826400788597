import { ThemeProvider } from '@emotion/react';
import { oneHuluLightTheme } from '@hulu/web-ui/style/createTheme';
import { WebVitals } from '@hulu/web-vitals';
import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import smoothscroll from 'smoothscroll-polyfill';

import { CMS_PAGETYPE } from '!app/lib/constants';
import { initHotJar } from '!app/lib/external/hotjar';
import '../app/styles/global.scss';
import '../app/styles/grids.scss';
import '../app/styles/html.scss';
import '../app/styles/fonts.scss';
import '@solo/mydisney-ui/dist/style.css';

const BROWSER_NAV_TYPE = 'navigation';
const BROWSER_NAV_TYPE_BACK = 'back_forward';

function Hitch(context) {
  const { Component, pageProps, router, ...props } = context;
  const {
    layout: { options: { enableHotjar } = {} } = {},
    pageTitle,
    cartAbandonment,
    pageType,
  } = pageProps;

  /**
   * Checks if user arrived at the page via the Back/Forward button
   */
  const isFromBackButton = () => {
    const perfEntries = performance?.getEntriesByType(BROWSER_NAV_TYPE);
    return (
      perfEntries?.length && perfEntries[0]?.type === BROWSER_NAV_TYPE_BACK
    );
  };

  useEffect(() => {
    /**
     * Reload the page if the user navigated from the Back button and is a Cart Abandoner
     */
    if (isFromBackButton() && cartAbandonment) {
      router.reload();
    }
  }, []);

  useEffect(() => {
    /**
     * 3rd party
     */
    require('lazysizes');

    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    /**
     * Hotjar Integration only for pages with enableHotjar field enabled.
     * Hookup defaults it to false if not enabled.
     */
    if (enableHotjar) {
      const hjid = 1250581;
      const hjsv = 6;
      initHotJar(hjid, hjsv);
    }
  }, []);

  const { datadogRum, huluEnv } = getConfig().publicRuntimeConfig;
  const queryClient = new QueryClient();
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        {/* eslint-disable-next-line inclusive-language/use-inclusive-words */}
        {/* https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md */}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <WebVitals
        applicationId={datadogRum.applicationId}
        clientToken={datadogRum.clientToken}
        env={huluEnv}
        service="hitch"
        sampleRate={1}
        version={__APP_VERSION__}
      />
      <QueryClientProvider client={queryClient}>
        {pageType === CMS_PAGETYPE.micro ? (
          <Component {...props} {...pageProps} />
        ) : (
          <ThemeProvider theme={oneHuluLightTheme}>
            <Component {...props} {...pageProps} />
          </ThemeProvider>
        )}
      </QueryClientProvider>
    </>
  );
}

Hitch.getInitialProps = async (appContext) => {
  const { pageProps } = await App.getInitialProps(appContext);
  const { layout: { title, options } = {} } = pageProps;
  const contentOverrides = options?.contentOverrides;

  return {
    pageProps: {
      pageTitle: contentOverrides?.overrideName || title,
      ...pageProps,
    },
  };
};

export default Hitch;
